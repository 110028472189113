import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './navigation-links.module.css'

const NavigationLinks = (props) => {
  return (
    <nav className={` ${styles['Nav']} ${styles[props.rootClassName]} `} onClick={props.click}>
      <span className={projectStyles['navbar-link']}>{props.text}</span>
      <a href="https://docs.lazerpent.com" target={'_blank'} className={` ${styles['text1']} ${projectStyles['navbar-link']} `}>
        {props.text1}
      </a>
      <a className={` ${styles['text2']} ${projectStyles['navbar-link']} `}>
        {props.text2}
      </a>
      <a href="https://lzpt.io/" target={"_blank"} className={` ${styles['text3']} ${projectStyles['navbar-link']} `}>
        {props.text3}
      </a>
      {/*<span className={` ${styles['text4']} ${projectStyles['navbar-link']} `}>*/}
      {/*  {props.text4}*/}
      {/*</span>*/}
    </nav>
  )
}

NavigationLinks.defaultProps = {
  rootClassName: '',
  // text3: 'Blog',
  text: 'Home',
  // text4: 'Pricing',
  text1: 'Command List',
  text2: 'Change Log',
  text3: 'Url Shorter (lzpt.io)'
}

NavigationLinks.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  rootClassName: PropTypes.string,
  text4: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
}

export default NavigationLinks
