import React from 'react'
import {Link} from 'react-router-dom'

import {Helmet} from 'react-helmet'

import Navbar from '../components/navbar'
import FeatureCard2 from '../components/feature-card2'
import TestimonialCard1 from '../components/testimonial-card1'
import GridCard from '../components/grid-card'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import projectStyles from '../style.module.css'
import styles from './home.module.css'

const Home = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Lazerpent.com</title>
        <meta property="og:title" content="Lazerpent.com"/>
        <meta
          property="og:description"
          content="The Best Torn City Discord Bot, Period."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/62b21300-f3ad-40e7-bae5-957175022ffc/b45c861f-7f5b-4d41-80f5-9f18fd619873?org_if_sml=1"
        />
      </Helmet>
      <Navbar/>
      <main className={styles['Main']}>
        <div
          className={` ${styles['Hero']} ${projectStyles['section-container']} `}
        >
          <div
            className={` ${styles['max-width']} ${projectStyles['max-content-container']} `}
          >
            <div className={styles['content-container']}>
              <h1 className={styles['text']}>
                <span>
                  The Best Torn City Discord Bot
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className={styles['text02']}>Period</span>
              </h1>
              <div className={styles['Banner']}>
                <h1 className={styles['text03']}>A Simple Monthly Fee</h1>
                <span className={styles['text04']}>
                  <span> A small fee of either</span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  {/*<span><$3 USD or $45m TCD</span>*/}
                  <span>$45m TCD</span>
                  <span>
                    {' '}
                    per month to cover basic server costs. Can be paid in
                    advance!
                  </span>
                </span>
                <div className={styles['BtnGroup']}>
                  <button
                    className={` ${styles['button']} ${projectStyles['button']} `}
                  >
                    Get it Now
                  </button>
                  <a
                    href="https://discord.gg/TornCityPark"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={` ${styles['link']} ${projectStyles['button']} `}
                  >
                    See it in Action
                  </a>
                </div>
              </div>
              <div className={styles['features-container']}>
                <div className={styles['feature']}>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className={styles['icon']}
                  >
                    <path
                      d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>
                  </svg>
                  <span className={styles['text09']}>
                    The BEST Giveaway (lottery) system available.
                  </span>
                </div>
                <div className={styles['feature1']}>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className={styles['icon02']}
                  >
                    <path
                      d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>
                  </svg>
                  <span className={styles['text10']}>
                    Fully integrated Torn commands using Torn API.
                  </span>
                </div>
                <div className={styles['feature2']}>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className={styles['icon04']}
                  >
                    <path
                      d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>
                  </svg>
                  <span className={styles['text11']}>
                    Amazing support, fast bug fixes, constant updates.
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['image-container']}>
              <img
                alt="Lotto Bot Demo"
                src="/playground_assets/enterdraw2-1200w.png"
                className={styles['Image']}
              />
            </div>
          </div>
        </div>
        <div
          className={` ${styles['SectionOne']} ${projectStyles['section-container']} `}
        >
          <div
            className={` ${styles['max-width1']} ${projectStyles['max-content-container']} `}
          >
            <h2
              className={` ${styles['text28']} ${projectStyles['heading2']} `}
            >
              <span>One bot for everything Torn City</span>
            </h2>
            <div className={styles['cards-container']}>
              <div className={styles['Card']}>
                <img
                  alt="image"
                  src="/playground_assets/gifticon-200h.png"
                  className={styles['image01']}
                />
                <h4
                  className={` ${styles['text30']} ${projectStyles['heading4']} `}
                >
                  Giveaways (Lottos)
                </h4>
                <span
                  className={` ${styles['text31']} ${projectStyles['content-Light']} `}
                >
                  The most advanced and feature packed giveaway system out
                  there.
                </span>
                <a href="#giveaway_system" className={styles['link1']}>
                  <span>More Information &gt;</span>
                </a>
              </div>
              <div className={styles['Card1']}>
                <img
                  alt="image"
                  src="/playground_assets/terminal-200h.png"
                  className={styles['image02']}
                />
                <h4
                  className={` ${styles['text33']} ${projectStyles['heading4']} `}
                >
                  Torn API Integrations
                </h4>
                <span
                  className={` ${styles['text34']} ${projectStyles['content-Light']} `}
                >
                  Look up real time Torn data including player profiles, company
                  / faction information, and more!
                </span>
                <a href="https://docs.lazerpent.com" target={'_blank'} className={styles['text35']}>View Command
                  List &gt;</a>
              </div>
              <div className={styles['Card2']}>
                <img
                  alt="image"
                  src="/playground_assets/bug.png"
                  className={styles['image03']}
                />
                <h4
                  className={` ${styles['text36']} ${projectStyles['heading4']} `}
                >
                  Always Updating
                </h4>
                <span
                  className={` ${styles['text37']} ${projectStyles['content-Light']} `}
                >
                  With updates coming out regularly and bugs being squashed quickly, there is always something new happening
                </span>
                <span className={styles['text38']}>View Change Log &gt;</span>
                {/*<Modal>*/}


                {/*</Modal>*/}
              </div>
              {/*<div className={styles['Card3']}>*/}
              {/*  <img*/}
              {/*    alt="image"*/}
              {/*    src="/playground_assets/paper-200h.png"*/}
              {/*    className={styles['image04']}*/}
              {/*  />*/}
              {/*  <h4*/}
              {/*    className={` ${styles['text39']} ${projectStyles['heading4']} `}*/}
              {/*  >*/}
              {/*    Read newspapers*/}
              {/*  </h4>*/}
              {/*  <span*/}
              {/*    className={` ${styles['text40']} ${projectStyles['content-Light']} `}*/}
              {/*  >*/}
              {/*    Amet minim mollit non deserunt ullamco est sit aliqua dolor do*/}
              {/*    amet sint. Velit officia consequat duis enim velit mollit.*/}
              {/*  </span>*/}
              {/*  <span className={styles['text41']}>Learn more &gt;</span>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/*<div className={projectStyles['section-container']}>*/}
        {/*  <div*/}
        {/*    className={` ${styles['max-width2']} ${projectStyles['max-content-container']} `}*/}
        {/*  >*/}
        {/*    <div className={styles['image-container1']}>*/}
        {/*      <img*/}
        {/*        alt="image"*/}
        {/*        src="/playground_assets/npc-full.png"*/}
        {/*        className={styles['image05']}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className={styles['content-container1']}>*/}
        {/*      <h1*/}
        {/*        className={` ${styles['text42']} ${projectStyles['heading2']} `}*/}
        {/*      >*/}
        {/*        <span>*/}
        {/*          Free bug fixes.*/}
        {/*          <span*/}
        {/*            dangerouslySetInnerHTML={{*/}
        {/*              __html: ' ',*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        </span>*/}
        {/*        <span className={styles['text50']}>Forever</span>*/}
        {/*      </h1>*/}
        {/*      <span*/}
        {/*        className={` ${styles['text45']} ${projectStyles['content-Light']} `}*/}
        {/*      >*/}
        {/*        <span>*/}
        {/*          Amet minim mollit non deserunt ullamco est sit aliqua dolor do*/}
        {/*          amet sint. Velit officia consequat duis enim velit mollit.*/}
        {/*          Velit officia consequat duis enim velit mollit.*/}
        {/*        </span>*/}
        {/*        <br/>*/}
        {/*        <span>Lorem ipsum dolor sit amet.</span>*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={styles['Features']}>
          <h1 id="giveaway_system" className={`${styles['features-header']} ${styles['text48']}`}>
            <span>A Giveaway (Lotto) System</span>
            <span className={styles['text50']}>Like No Other</span>
            <span className={styles['text51']}/>
          </h1>
          <div className={styles['container1']}>
            <FeatureCard2
              title="Advanced Total Tracking"
              description="Allows up to 10 prizes per giveaway, the giveaway value is tracked for both user and overall server data. Can be looked up both individually and in leaderboard format."
              rootClassName="rootClassName"
            />
            <FeatureCard2
              title="Multiple Modes"
              description="Mix and match your favorite giveaway modes. Toggle if users should be pinged, if users can buy entries with Karma, or if the prize grows the more members enter!"
              rootClassName="rootClassName6"
            />
            <FeatureCard2
              title="Lotto Lock"
              description='An intelligent locking system which makes sure that no one can interrupt your members running giveaways. Tracks totals per giveaway "run", giving members more insight into their giveaways.'
              rootClassName="rootClassName3"
            />
            <FeatureCard2
              title="Toggleable Pings per User"
              description="Each user can select if they would like to be pinged for pinged giveaways. Pings are done via a customizable role which is toggled on / off."
              rootClassName="rootClassName7"
            />
            <FeatureCard2
              title="Automatic Send Line"
              description="Using your Torn API key, the send line can be automatically generated, making running giveaways easier for both PC and mobile users."
              rootClassName="rootClassName2"
            />
            <FeatureCard2
              title="First ID Minigame"
              description="Reward your active members! Run a First ID giveaway to give a prize to active members watching the channel who know their Torn ID by heart."
              rootClassName="rootClassName1"
            />
            <FeatureCard2
              title="Activity Rewards"
              description="Using Karma, users are rewarded for good sportsmanship as well as being active in chat. Karma can be spent to buy extra entries in a Karma enabled lotto."
              rootClassName="rootClassName5"
            />
            <FeatureCard2
              title="Automatic Draw and Reminder"
              description="Giveaway starters can have the bot automatically draw the giveaway after an amount of time. The bot also will automatically remind the user after a configurable amount of time if they have not drawn their lotto."
              rootClassName="rootClassName4"
            />
          </div>
        </div>
        <div className={styles['Testimonial']}>
          <div className={styles['Testimonial1']}>
            <div className={styles['container2']}>
              <h1 className={styles['text52']}>
                <span style={{paddingTop: '20px'}} id='testimonials'>What they’re saying</span>
              </h1>
              <div className={styles['container3']}>
                <TestimonialCard1
                  name="Exie"
                  role="Owner, Torn City Park"
                  quote="The lottery functions all work beautifully and the API functions are all seamless! I not only would recommend this bot to anyone that wants to do giveaways, but I have and would again."
                  picture_alt="TCP Logo"
                  picture_src="/playground_assets/tcplow-200h.webp"
                  rootClassName="rootClassName2"
                />
                <TestimonialCard1
                  name="Annie4477"
                  role="Leader, 709 Faction Family"
                  quote="The bot is amazing and creates a fun, active environment. Once added, my server came to life with activity. Not only do we benefit from the lotto, we also have been loving the other features.  Love this bot. Won't go without it."
                  picture_alt="709 Logo"
                  picture_src="/playground_assets/709logo.webp"
                  rootClassName="rootClassName"
                />
                <TestimonialCard1
                  name="Father_of_5"
                  role="Owner, The Olympus"
                  picture_alt="Party Logo"
                  picture_src="/playground_assets/olympus.webp"
                  rootClassName="rootClassName1"
                />
              </div>
            </div>
          </div>
        </div>
        {/*<div className={projectStyles['section-container']}>*/}
        {/*  <div*/}
        {/*    className={` ${styles['max-width3']} ${projectStyles['max-content-container']} `}*/}
        {/*  >*/}
        {/*    <div className={styles['content-container2']}>*/}
        {/*      <h1*/}
        {/*        className={` ${styles['text54']} ${projectStyles['heading2']} `}*/}
        {/*      >*/}
        {/*        <span>A </span>*/}
        {/*        <span className={styles['text50']}>top tier</span>*/}
        {/*        <span> faction bot.</span>*/}
        {/*      </h1>*/}
        {/*      <span*/}
        {/*        className={` ${styles['text57']} ${projectStyles['content-Light']} `}*/}
        {/*      >*/}
        {/*        <span>*/}
        {/*          An included system, free of charge, targeted to factions who want full management and easy to use commands for their discord. This system is still being developed and new things are being added all the time!*/}
        {/*        </span>*/}
        {/*      </span>*/}
        {/*      <button*/}
        {/*        className={` ${styles['button']} ${projectStyles['button']} `}*/}
        {/*        onClick={() => window.open("https://forms.gle/xepwJxjFpineBjGm9", "_blank")}*/}
        {/*      >*/}
        {/*        Request a Faction Bot Feature*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className={styles['image-container2']}>*/}
        {/*      <img*/}
        {/*        alt="image"*/}
        {/*        src="/playground_assets/faction-banker.png"*/}
        {/*        className={styles['Image']}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={projectStyles['section-container']} style={{paddingTop: '0'}}>
          <div className={styles['Stats']}>
            <div className={styles['Stat']}>
              <svg viewBox="0 0 1152 1024" className={styles['icon06']}>
                <path
                  d="M768 770.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"/>
                <path
                  d="M327.196 795.328c55.31-36.15 124.080-63.636 199.788-80.414-15.054-17.784-28.708-37.622-40.492-59.020-30.414-55.234-46.492-116.058-46.492-175.894 0-86.042 0-167.31 30.6-233.762 29.706-64.504 83.128-104.496 159.222-119.488-16.914-76.48-61.94-126.75-181.822-126.75-192 0-192 128.942-192 288 0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h279.006c14.518-12.91 30.596-25.172 48.19-36.672z"/>
              </svg>
              <span className={styles['text12']}>Unique Users</span>
              <span className={styles['text13']}>
                Across over 15 separate servers!
              </span>
              <h1 className={styles['text14']}>
                <span>4000+</span>
              </h1>
            </div>
            <div className={styles['Stat1']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon09']}>
                <path
                  d="M598 512h234l-234-234v234zM640 214l256 256v426q0 34-26 60t-60 26h-470q-34 0-59-26t-25-60v-598q0-34 26-59t60-25h298zM682 42v86h-512v598h-84v-598q0-34 25-60t59-26h512z"/>
              </svg>
              <span className={styles['text16']}>
                <span>Commands</span>
              </span>
              <span className={styles['text18']}>
                <span>
                  View the
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a href="https://docs.lazerpent.com" target={'_blank'} className={styles['text20']}>command list</a>
                <span> for details!</span>
              </span>
              <h1 className={styles['text22']}>
                <span>75+</span>
              </h1>
            </div>
            <div className={styles['Stat2']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon11']}>
                <path
                  d="M1024 282.5l-90.506-90.5-178.746 178.752-101.5-101.502 178.75-178.75-90.5-90.5-178.75 178.75-114.748-114.75-86.626 86.624 512.002 512 86.624-86.622-114.752-114.752 178.752-178.75z"/>
                <path
                  d="M794.040 673.79l-443.824-443.824c-95.818 114.904-204.52 292.454-129.396 445.216l-132.248 132.248c-31.112 31.114-31.112 82.024 0 113.136l14.858 14.858c31.114 31.114 82.026 31.114 113.138 0l132.246-132.244c152.764 75.132 330.318-33.566 445.226-129.39z"/>
              </svg>
              <span className={styles['text24']}>Online Time</span>
              <span className={styles['text25']}>
                Hosted in the cloud, the bot is always online!
              </span>
              <h1 className={styles['text26']}>
                <span>24/7</span>
              </h1>
            </div>
          </div>
          {/*<div*/}
          {/*  className={` ${styles['max-width4']} ${projectStyles['max-content-container']} `}*/}
          {/*>*/}
          {/*  <div className={styles['heading-container']}>*/}
          {/*    <h2*/}
          {/*      className={` ${styles['text60']} ${projectStyles['heading2']} `}*/}
          {/*    >*/}
          {/*      <span>Get rewarded for </span>*/}
          {/*      <span className={styles['text63']}>referrals</span>*/}
          {/*    </h2>*/}
          {/*    <span className={projectStyles['content-Light']}>*/}
          {/*      <span>*/}
          {/*        If you refer someone and they continue using the bot I will give you a free month in thanks*/}
          {/*      </span>*/}
          {/*      <span className={styles['text66']}/>*/}
          {/*      <span></span>*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*<div className={styles['cards-container1']}>*/}
          {/*  <GridCard image_src="/playground_assets/vector%203-200h.png"/>*/}
          {/*  <GridCard*/}
          {/*    text="Follow your friends"*/}
          {/*    image_src="/playground_assets/vector%203%20%5B1%5D-200h.png"*/}
          {/*  />*/}
          {/*  <GridCard*/}
          {/*    text="Declutter your life inbox"*/}
          {/*    image_src="/playground_assets/vector%203%20%5B2%5D-200h.png"*/}
          {/*  />*/}
          {/*  <GridCard*/}
          {/*    text="Less apps, more space"*/}
          {/*    image_src="/playground_assets/vector%203%20%5B3%5D-200h.png"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
        <div
          className={` ${styles['SectionSix']} ${projectStyles['section-container']} `}
        >
          <div
            className={` ${styles['max-width5']} ${projectStyles['max-content-container']} `}
          >
            <div className={styles['content-container3']}>
              <h1
                className={` ${styles['text68']} ${projectStyles['heading2']} `}
              >
                {/*<span>Get started today.</span>*/}
                {/*<br/>*/}
                <span className={styles['text70']}>Get started today</span>
              </h1>
              <span className={` ${styles['text71']} ${projectStyles['content-Light']} `}>
                Not sure if you want it? Get a week for free to make sure its right for your community!
                </span>
              <button className={` ${styles['button']} ${projectStyles['button']} `}>
                Get it Now
              </button>

              {/*<div className={styles['input-container']}>*/}
              {/*  <div*/}
              {/*    className={` ${styles['container4']} ${projectStyles['input']} `}*/}
              {/*  >*/}
              {/*    <svg*/}
              {/*      viewBox="0 0 804.5714285714286 1024"*/}
              {/*      className={styles['icon14']}*/}
              {/*    >*/}
              {/*      <path*/}
              {/*        d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"/>*/}
              {/*    </svg>*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      placeholder="Your phone number..."*/}
              {/*      className={` ${styles['textinput']} ${projectStyles['input']} `}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <button*/}
              {/*    className={` ${styles['button1']} ${projectStyles['button-primary']} ${projectStyles['button']} `}*/}
              {/*  >*/}
              {/*    Get started*/}
              {/*  </button>*/}
              {/*</div>*/}
              {/*<div className={styles['features-container1']}>*/}
              {/*  <div className={styles['feature3']}>*/}
              {/*    <svg*/}
              {/*      viewBox="0 0 877.7142857142857 1024"*/}
              {/*      className={styles['icon16']}*/}
              {/*    >*/}
              {/*      <path*/}
              {/*        d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>*/}
              {/*    </svg>*/}
              {/*    <span className={styles['text72']}>*/}
              {/*      The BEST Giveaway (lottery) system available.*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*  <div className={styles['feature4']}>*/}
              {/*    <svg*/}
              {/*      viewBox="0 0 877.7142857142857 1024"*/}
              {/*      className={styles['icon18']}*/}
              {/*    >*/}
              {/*      <path*/}
              {/*        d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>*/}
              {/*    </svg>*/}
              {/*    <span className={styles['text73']}>*/}
              {/*      Fully integrated Torn commands using Torn API.*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*  <div className={styles['feature5']}>*/}
              {/*    <svg*/}
              {/*      viewBox="0 0 877.7142857142857 1024"*/}
              {/*      className={styles['icon20']}*/}
              {/*    >*/}
              {/*      <path*/}
              {/*        d="M733.714 419.429c0-9.714-3.429-19.429-10.286-26.286l-52-51.429c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-233.143 232.571-129.143-129.143c-6.857-6.857-16-10.857-25.714-10.857s-18.857 4-25.714 10.857l-52 51.429c-6.857 6.857-10.286 16.571-10.286 26.286s3.429 18.857 10.286 25.714l206.857 206.857c6.857 6.857 16.571 10.857 25.714 10.857 9.714 0 19.429-4 26.286-10.857l310.286-310.286c6.857-6.857 10.286-16 10.286-25.714zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"/>*/}
              {/*    </svg>*/}
              {/*    <span className={styles['text74']}>*/}
              {/*      Amazing support, fast bug fixes, constant updates.*/}
              {/*    </span>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            {/*<div className={styles['image-container3']}>*/}
            {/*  <img*/}
            {/*    alt="image"*/}
            {/*    src="/playground_assets/group%2032-1200w.png"*/}
            {/*    className={styles['image09']}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      </main>
      {/*<Footer/>*/}
      <Copyright/>
    </div>
  )
}

export default Home
